import jewelry from "../../assets/icons/Necklace.svg";
import keychain from "../../assets/icons/keychain.svg";
import bracelet from "../../assets/icons/bracelet.svg";
import tumblers from "../../assets/icons/tumblers.svg";
import ornaments from "../../assets/icons/ornaments.svg";
import products from "../../assets/icons/total_products.svg";

export const productData = [
  {
    title: "Total Products",
    icon: products,
    total: (stats) => ({
      sold: stats?.sold_products_count,
      active: stats?.total_products_count,
    }),
    index: null,
  },
  {
    title: "Ornaments",
    icon: ornaments,
    total: (stats) => ({
      "etsy Sold": stats?.ornaments_sold?.etsy_sold,
      "amazon Sold": stats?.ornaments_sold?.amazon_sold,
      "ICP Sold": stats?.ornaments_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[1]?.total
        : 0,
    }),
    index: 1,
  },
  {
    title: "Tumblers",
    icon: tumblers,
    total: (stats) => ({
      "etsy Sold": stats?.tumblers_sold?.etsy_sold,
      "amazon Sold": stats?.tumblers_sold?.amazon_sold,
      "ICP Sold": stats?.tumblers_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[0]?.total
        : 0,
    }),
    index: 0,
  },
  {
    title: "Frosted Cups",
    icon: tumblers,
    total: (stats) => ({
      "etsy Sold": stats?.frosted_cup_sold?.etsy_sold,
      "amazon Sold": stats?.frosted_cup_sold?.amazon_sold,
      "ICP Sold": stats?.frosted_cup_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[11]?.total
        : 0,
    }),
    index: 11,
  },
  {
    title: "Jewelry",
    icon: jewelry,
    total: (stats) => ({
      "etsy Sold": stats?.jewellry_sold?.etsy_sold,
      "amazon Sold": stats?.jewellry_sold?.amazon_sold,
      "ICP Sold": stats?.jewellry_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[2]?.total
        : 0,
    }),
    index: 2,
  },
  {
    title: "Bracelet",
    icon: bracelet,
    total: (stats) => ({
      "etsy Sold": stats?.bracelet_sold?.etsy_sold,
      "amazon Sold": stats?.bracelet_sold?.amazon_sold,
      "ICP Sold": stats?.bracelet_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[9]?.total
        : 0,
    }),
    index: 9,
  },
  {
    title: "Keychain",
    icon: keychain,
    total: (stats) => ({
      "etsy Sold": stats?.keychain_sold?.etsy_sold,
      "amazon Sold": stats?.keychain_sold?.amazon_sold,
      "ICP Sold": stats?.keychain_sold?.local_sold,
      active: stats?.catalog_wise_products
        ? stats?.catalog_wise_products[10]?.total
        : 0,
    }),
    index: 10,
  },
];
