export const addProductToCart = (productCount) => async (dispatch) => {
  dispatch({
    type: "addTocart",
    payload: productCount,
  });
};

export const setProductsList = (data) => async (dispatch) => {
  dispatch({
    type: "setProductsList",
    payload: data,
  });
};

export const setProductsMeta = (meta) => async (dispatch) => {
  dispatch({
    type: "setProductsMeta",
    payload: meta,
  });
};

export const setProductSearched = (boolean) => async (dispatch) => {
  dispatch({
    type: "setProductSearched",
    payload: boolean,
  });
};

export const setUploadedGraphicImage = (src, filename) => async (dispatch) => {
  dispatch({
    type: "setUploadedGraphicImage",
    payload: { src, filename },
  });
};

export const setUploadedNotecardImage = (src, filename) => async (dispatch) => {
  dispatch({
    type: "setUploadedNotecardImage",
    payload: { src, filename },
  });
};

export const setPreviewImageSRC = (src, filename) => async (dispatch) => {
  dispatch({
    type: "setPreviewImageSRC",
    payload: { src, filename },
  });
};

export const setGraphicURL = (url) => async (dispatch) => {
  dispatch({
    type: "setGraphicURL",
    payload: url,
  });
};

export const setNotecardURL = (url) => async (dispatch) => {
  dispatch({
    type: "setNotecardURL",
    payload: url,
  });
};
