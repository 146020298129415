import React, { useEffect } from "react";
import products from "../../../assets/icons/total_products.svg";
import tumblers from "../../../assets/icons/tumblers.svg";
import ornaments from "../../../assets/icons/ornaments.svg";
import jewelry from "../../../assets/icons/Necklace.svg";
import bracelet from "../../../assets/icons/bracelet.svg";
import keychain from "../../../assets/icons/keychain.svg";

import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
} from "@mui/material";
import { Typography } from "@mui/material";
import ProductsBarChart from "./ProductsBarChart";
import { sentenceCase } from "change-case";
import MeetingNotes from "./meetingNotes";
import request from "src/utils/request";
import { useDispatch, useSelector } from "react-redux";
import { toastify } from "src/utils/toast";
import Iconify from "src/components/iconify";
import { setUserStats } from "src/redux/actions/userActions";

const UserStats = ({ row, avatarUrl, setShowUserStats }) => {
  const dispatch = useDispatch();
  const userStats = useSelector((state) => state.user.userStats);

  useEffect(() => {
    const getUserStats = async () => {
      try {
        const res = await request.get(`/users/stats/${row.id}`);

        if (res) {
          let data = res.data.data;
          dispatch(
            setUserStats({
              userData: row,
              stats: data,
            })
          );
        }
      } catch (e) {
        toastify("error", e.response.data.message);
      }
    };

    getUserStats();
  }, [dispatch, row]);

  const StatWidget = ({ title, value, card_color, icon }) => {
    return (
      <Grid item xs={12} sm={8} md={6} xl={4}>
        <Paper
          sx={{
            color: (theme) => theme.palette[card_color].contrastText,
            backgroundColor: (theme) => theme.palette[card_color].main,
            padding: "16px",
            height: 80,
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Paper
                sx={{
                  backgroundColor: (theme) => theme.palette[card_color].main,
                }}
              >
                <img src={icon} alt="stat-icon" width={40} height={40} />
              </Paper>

              <Typography variant="h6"> {title} </Typography>
            </Stack>

            {title === "Total Products Count" ? (
              <Typography variant="h6"> {value}</Typography>
            ) : (
              <Stack
                direction={"column"}
                alignItems={"flex-start"}
                width={"30%"}
              >
                <Typography variant="h6">Etsy: {value?.etsy}</Typography>
                <Typography variant="h6">Amazon: {value?.amazon}</Typography>
              </Stack>
            )}
          </Stack>
        </Paper>
      </Grid>
    );
  };

  return (
    <Container sx={{ my: 3 }}>
      <Stack direction={"row"} alignItems={"center"} alignContent={"center"}>
        <IconButton onClick={() => setShowUserStats([])}>
          <Iconify icon="ph:arrow-left-fill" />
        </IconButton>
        <Typography variant="h4">User Stats</Typography>
      </Stack>
      <Grid container spacing={3} direction={"row"} mt={2}>
        <Grid item xs={4}>
          {" "}
          <Stack direction={"column"} sx={{ m: 2 }} spacing={2}>
            <Avatar
              alt={"user_avatar"}
              src={avatarUrl}
              sx={{ width: 120, height: 120 }}
            />
            <Stack direction={"column"} spacing={0.4}>
              <Typography variant="h5">{userStats?.userData?.name}</Typography>
              <Typography variant="subtitle2">
                Email: {userStats?.userData?.email}
              </Typography>
              <Typography variant="subtitle2">
                Role: {userStats?.userData?.role}
              </Typography>
              <Typography variant="subtitle2">
                Status:{" "}
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    color:
                      userStats?.userData?.status === "Active"
                        ? "#229A16"
                        : "#b72136",
                    backgroundColor:
                      userStats?.userData?.status === "Active"
                        ? "rgba(84, 214, 44, 0.16)"
                        : "rgba(255, 72, 66, 0.16)",
                  }}
                >
                  {userStats
                    ? sentenceCase(
                        userStats?.userData?.status
                          ? userStats?.userData?.status
                          : ""
                      )
                    : ""}
                </Button>
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <ProductsBarChart stats={userStats?.stats} />
        </Grid>
      </Grid>
      <Grid container spacing={2} my={2}>
        <StatWidget
          title={"Total Products Count"}
          value={userStats ? userStats?.stats?.total_products_count : 0}
          card_color="card_one"
          icon={products}
        />
        <StatWidget
          title={"Ornaments"}
          value={
            userStats
              ? {
                  etsy: userStats?.stats?.catalog_wise_counts_etsy_amazon
                    .ornament.etsy,
                  amazon:
                    userStats?.stats?.catalog_wise_counts_etsy_amazon.ornament
                      .amazon,
                }
              : 0
          }
          card_color="card_one"
          icon={ornaments}
        />
        <StatWidget
          title={"Tumblers"}
          value={
            userStats
              ? {
                  etsy: userStats?.stats?.catalog_wise_counts_etsy_amazon
                    .tumbler.etsy,
                  amazon:
                    userStats?.stats?.catalog_wise_counts_etsy_amazon.tumbler
                      .amazon,
                }
              : 0
          }
          card_color="card_one"
          icon={tumblers}
        />

        <StatWidget
          title={"Frosted Cups"}
          value={
            userStats
              ? {
                  etsy: userStats?.stats?.catalog_wise_counts_etsy_amazon
                    .frosted_cup.etsy,
                  amazon:
                    userStats?.stats?.catalog_wise_counts_etsy_amazon
                      .frosted_cup.amazon,
                }
              : 0
          }
          card_color="card_one"
          icon={tumblers}
        />
        <StatWidget
          title={"Jewelry"}
          value={
            userStats
              ? {
                  etsy: userStats?.stats?.catalog_wise_counts_etsy_amazon
                    .jewellery.etsy,
                  amazon:
                    userStats?.stats?.catalog_wise_counts_etsy_amazon.jewellery
                      .amazon,
                }
              : 0
          }
          card_color="card_one"
          icon={jewelry}
        />
        <StatWidget
          title={"Bracelet"}
          value={
            userStats
              ? {
                  etsy: userStats?.stats?.catalog_wise_counts_etsy_amazon
                    .bracelet.etsy,
                  amazon:
                    userStats?.stats?.catalog_wise_counts_etsy_amazon.bracelet
                      .amazon,
                }
              : 0
          }
          card_color="card_one"
          icon={bracelet}
        />

        <StatWidget
          title={"Keychain"}
          value={
            userStats
              ? {
                  etsy: userStats?.stats?.catalog_wise_counts_etsy_amazon
                    .keychain.etsy,
                  amazon:
                    userStats?.stats?.catalog_wise_counts_etsy_amazon.keychain
                      .amazon,
                }
              : 0
          }
          card_color="card_one"
          icon={keychain}
        />
      </Grid>
      <Box sx={{ my: 3 }}></Box>
      <MeetingNotes userID={row.id} />
    </Container>
  );
};

export default UserStats;
