import React from "react";
import { CardMedia, IconButton, Paper, Typography, styled } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Box } from "@mui/system";
import LibraryImg from "../../assets/images/library.png";
import Iconify from "../iconify/Iconify";

const StyledDropzone = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.grey[200],
  textAlign: "center",
  cursor: "pointer",
}));

const UploadImage = ({ state, setState, disable, clearState }) => {
  const onDrop = (acceptedFiles) => {
    setState(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <>
      <StyledDropzone
        elevation={3}
        {...getRootProps()}
        className={disable ? "disabled-dropzone" : ""}
      >
        <input {...getInputProps()} multiple disabled={disable} />
        <img src={LibraryImg} alt="library" style={{ width: 120 }} />
        <div>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Drag Your Files Here
          </Typography>
        </div>
      </StyledDropzone>
      <Box sx={{ my: 4 }}></Box>
      {state?.length
        ? state?.map((file, index) => (
            <Box
              key={index}
              sx={{ position: "relative", width: "80px", height: "80px" }}
            >
              <IconButton
                onClick={clearState}
                sx={{
                  position: "absolute",
                  backgroundColor: "#C4CDD5",
                  right: -10,
                  top: -10,
                  "&:hover": {
                    backgroundColor: "#919EAB",
                  },
                }}
              >
                <Iconify icon="eva:close-fill" color="#fff" />
              </IconButton>
              <CardMedia
                component={"img"}
                image={
                  typeof file === "string" ? file : URL.createObjectURL(file)
                }
                alt={"Graphic"}
                sx={{ width: "80px", height: "80px", mb: 2 }}
              />
            </Box>
          ))
        : ""}
    </>
  );
};

export default UploadImage;
