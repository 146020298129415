const defaultState = {
  productsInCart: 0,
  productList: [],
  productsMeta: {},
  uploadedGraphicImage: {
    src: null,
    filename: "",
  },
  uploadedNotecardImage: {
    src: {},
    filename: "",
  },
  previewImageSRC: { src: {}, filename: "" },
  graphicURL: "",
  notecardURL: "",
  productSearched: false,
};

const productReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "addTocart":
      return {
        ...state,
        productsInCart: action.payload,
      };

    case "setProductsList":
      return {
        ...state,
        productList: action.payload,
      };

    case "setProductsMeta":
      return {
        ...state,
        productsMeta: action.payload,
      };

    case "setProductSearched":
      return {
        ...state,
        productSearched: action.payload,
      };

    case "setUploadedGraphicImage":
      return {
        ...state,
        uploadedGraphicImage: {
          src: action.payload.src,
          filename: action.payload.filename,
        },
      };

    case "setUploadedNotecardImage":
      return {
        ...state,
        uploadedNotecardImage: {
          src: action.payload.src,
          filename: action.payload.filename,
        },
      };

    case "setPreviewImageSRC":
      return {
        ...state,
        previewImageSRC: {
          src: action.payload.src,
          filename: action.payload.filename,
        },
      };

    case "setGraphicURL":
      return {
        ...state,
        graphicURL: action.payload,
      };

    case "setNotecardURL":
      return {
        ...state,
        notecardURL: action.payload,
      };

    default:
      return state;
  }
};

export default productReducer;
