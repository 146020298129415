import {
  Avatar,
  Box,
  Button,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { style } from "../home/utils";
import Iconify from "src/components/iconify/Iconify";

const EducationVideo = ({ item }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={3} my={2}>
        <Avatar
          sx={{
            bgcolor: theme.palette.primary.main,
          }}
        >
          <Iconify
            icon={"mdi:video-outline"}
            width={30}
            sx={{
              color: theme.palette.white.main,

              borderRadus: "16px",
            }}
          />
        </Avatar>

        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          {item?.name}
        </Typography>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="lightbox"
        >
          <Box sx={style}>
            <video
              id="my-video"
              className="video-js"
              controls
              preload="auto"
              width="800"
              height="570"
              poster={item?.media}
              data-setup="{}"
            >
              <source src={item?.media} type="video/mp4" />
            </video>
          </Box>
        </Modal>
      </Stack>
      <Button variant="contained" size="small" onClick={() => setOpen(true)}>
        Start Learning
      </Button>
    </Stack>
  );
};

export default EducationVideo;
